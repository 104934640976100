import React, { Component } from 'react'
import IPFS from 'ipfs-mini'
import { Api, JsonRpc } from 'eosjs'
import { JsSignatureProvider } from 'eosjs/dist/eosjs-jssig'

const EOS_HTTP_ENDPOINT = 'http://localhost:8888'
const CONTRACT_NAME = 'trendie'

const ipfs = new IPFS({ host: 'localhost', port: 5001, protocol: 'http' })

async function callContract({ action, pkey, username, data }: any) {
  const rpc = new JsonRpc(EOS_HTTP_ENDPOINT)
  const chainId =
    'cf057bbfb72640471fd910bcb67639c22df9f92470936cddc1ade0e2f2e7dc4f'
  const signatureProvider = new JsSignatureProvider([pkey])
  const api = new Api({
    rpc,
    chainId,
    signatureProvider,
    textDecoder: new TextDecoder(),
    textEncoder: new TextEncoder(),
  })

  try {
    const resultWithConfig = await api.transact(
      {
        actions: [
          {
            account: CONTRACT_NAME,
            name: action,
            authorization: [
              {
                actor: username,
                permission: 'active',
              },
            ],
            data,
          },
        ],
      },
      {
        blocksBehind: 3,
        expireSeconds: 30,
      }
    )
    return resultWithConfig
  } catch (err) {
    throw err
  }
}

async function submitProfile({
  name,
  username,
  bio,
  birthDate,
  url,
  image,
  pkey,
}: any) {
  // if (!name || !username || !image || !bio || !birthDate || !url || !pkey) {
  //   throw new Error('Please fill out all required form fields')
  // }
  return new Promise((resolve, reject) => {
    ipfs.addJSON(
      {
        name,
        username,
        bio,
        birthDate,
        url,
        image,
      },
      (error: Error, ipfsHash: string) => {
        if (!error) {
          callContract({
            action: 'login',
            username,
            pkey,
            data: { username, ipfsHash },
          })
            .then((result: any) => {
              resolve({
                result,
                ipfsHash,
              })
            })
            .catch((error: Error) => {
              reject(error)
            })
        } else {
          reject(error)
        }
      }
    )
  })
}

async function loadFile(file: any) {
  return new Promise((resolve) => {
    const reader = new FileReader()

    reader.addEventListener(
      'load',
      function readerCallback() {
        resolve(reader.result?.toString() || '')
      },
      false
    )

    if (file) {
      reader.readAsDataURL(file)
    }
  })
}

class Register extends Component {
  state = {
    artistIpfsHash: '',
    formMessage: '',
    loading: false,
    pkey: '',
    username: '',
    name: '',
    image: '',
    bio: '',
    dob: '',
    website: '',
  }

  onChange = (event: any) =>
    this.setState({ [event.target.name]: event.target.value })

  render() {
    return (
      <section className='register'>
        <header className='page-header text-center space-top-2x'>
          <h1>Register {this.state.name}</h1>
        </header>
        <div className='row contact-area'>
          <div className='col-sm-3'></div>
          <div className='col-sm-6 contact-form-area'>
            <div id='contact-form-1'>
              <form id='contactForm' data-toggle='validator'>
                <div className='form-group'>
                  <label htmlFor='private-key'>
                    Private key (only used locally)
                  </label>
                  <input
                    id='private-key'
                    type='text'
                    name='pkey'
                    className='form-control'
                    onChange={this.onChange}
                    value={this.state.pkey}
                    required
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='account-name'>EOS Account Name</label>
                  <input
                    type='text'
                    id='account-name'
                    name='username'
                    className='form-control'
                    value={this.state.username}
                    onChange={this.onChange}
                    placeholder='Lower case a-z, 1-5, dot, and max 12 characters'
                    required
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='name'>Artist Name</label>
                  <input
                    type='text'
                    onChange={this.onChange}
                    id='name'
                    name='name'
                    className='form-control'
                    value={this.state.name}
                    required
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='profile'>Profile image</label>
                  <input
                    onChange={(event) =>
                      loadFile(
                        event &&
                          event.target &&
                          event.target.files &&
                          event.target.files[0]
                      ).then((image) => this.setState({ image }))
                    }
                    className='form-control'
                    type='file'
                  />
                  {this.state.image && (
                    <img src={this.state.image} alt='artist profile' />
                  )}
                </div>
                <div className='form-group'>
                  <label htmlFor='bio'>Bio</label>
                  <textarea
                    id='bio'
                    name='bio'
                    className='form-control'
                    required
                    onChange={this.onChange}>
                    {this.state.bio}
                  </textarea>
                </div>
                <div className='form-group'>
                  <label htmlFor='dob'>Date of birth</label>
                  <input
                    type='date'
                    name='dob'
                    id='dob'
                    className='form-control'
                    onChange={this.onChange}
                    value={this.state.dob}
                    required
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='website'>Website</label>
                  <input
                    type='text'
                    id='website'
                    name='website'
                    className='form-control'
                    onChange={this.onChange}
                    value={this.state.website}
                  />
                </div>
                <button
                  type='submit'
                  id='form-submit'
                  className='btn btn-md btn-primary-filled btn-form-submit'
                  onClick={async (event) => {
                    event.preventDefault()
                    await submitProfile(this.state)
                      .then((result) => {
                        console.log(result)
                        this.setState({ formMessage: 'Success!' })
                      })
                      .catch((error) => {
                        this.setState({
                          formMessage: `Error: ${error.message}`,
                        })
                      })
                    return false
                  }}>
                  Register
                </button>
                <div id='msgSubmit' className='h3 text-center hidden'>
                  {this.state.formMessage}
                </div>
              </form>
            </div>
          </div>
          <div className='col-sm-3'></div>
        </div>
      </section>
    )
  }
}

export default Register
