import React from 'react'

const Artist = () => (
  <section id='page-content' className='container-fluid has-fixed-top'>
    <div className='section-w-image'>
      <div className='row'>
        <div className='col-md-5 about-image'>
          <p></p>
        </div>
        <div className='col-md-7 col-md-offset-5 section-description dark'>
          <h3>Biography</h3>
          <p>
            Phasellus vitae volutpat erat. Maecenas at gravida quam. Vestibulum
            vel pulvinar lorem. Nunc in rutrum est. Aenean auctor vitae libero
            non eleifend. Ut convallis libero et dignissim tristique. Mauris
            hendrerit fermentum lectus, non dignissim diam dapibus nec. Mauris
            at consequat ex. Vestibulum eu justo eu odio posuere ornare eu eget
            sapien. Nulla ut tincidunt metus. Vivamus tincidunt tellus quis
            consectetur dictum.
          </p>
        </div>
      </div>
    </div>
  </section>
)

export default Artist
