import React from 'react'
import { Link } from 'react-router-dom'

const Home = () => (
  <>
    <section className='Home' id='services'>
      <div id='services-banner' className='dark has-fixed-top'>
        <div className='banner text-center'>
          <div className='container-fluid'>
            <div className='page-header'>
              <h2>Trendie</h2>
            </div>
            <p>An art economy on the blockchain</p>
            <a href='#page-content' className='page-scroll'>
              <i className='lnr lnr-chevron-down scroll-down'></i>
            </a>
          </div>
        </div>
      </div>

      <div id='page-content' className='container-fluid'>
        <section id='services'>
          <div className='row'>
            <div className='col-sm-6 col-md-3 service-left'>
              <div className='service block'>
                <div className='service-icon'>
                  <i className='lnr lnr-camera'></i>
                </div>
                <h5>Establish yourself as an artist and promote your work</h5>
                <p>
                  Create an EOSIO account and then{' '}
                  <Link to='/register'>register</Link>, then start promoting
                  your work.
                </p>
              </div>
            </div>

            <div className='col-sm-6 col-md-3 service-left'>
              <div className='service block'>
                <div className='service-icon'>
                  <i className='lnr lnr-picture'></i>
                </div>
                <h5>Manage ownership and provenance of artwork</h5>
                <p>
                  Sell online and transfer ownership to demonstrate provenance
                  of original works.
                </p>
              </div>
            </div>

            <div className='col-sm-6 col-md-3 service-left'>
              <div className='service block'>
                <div className='service-icon'>
                  <i className='lnr lnr-users'></i>
                </div>
                <h5>Sell digital rights</h5>
                <p>Choose to sell digital rights for high definition files.</p>
              </div>
            </div>

            <div className='col-sm-6 col-md-3 service-left'>
              <div className='service block'>
                <div className='service-icon'>
                  <i className='lnr lnr-diamond'></i>
                </div>
                <h5>Buy original artwork</h5>
                <p>Browse artists and artworks and find art you love.</p>
              </div>
            </div>

            <div className='col-sm-6 col-md-3 service-left'>
              <div className='service block'>
                <div className='service-icon'>
                  <i className='lnr lnr-diamond'></i>
                </div>
                <h5>Create your own virtual gallery</h5>
                <p>Browse art and create your own VR gallery.</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  </>
)

export default Home
