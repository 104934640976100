import React, { Component } from 'react'

export default class Footer extends Component {
  state = {}
  render() {
    return (
      <>
        <footer className='light-footer fixed-bottom'>
          <div className='container-fluid'>
            <p className='footer-info'>
              © 2020 - <strong>Trendie - Cyber Gallery</strong> - Photography.
              All Rights Reserved.
              <span className='footer-contact-info'>
                <a href='mailto:hello@yoursite.com' className='footer-ci'>
                  <i className='lnr lnr-envelope'></i>hello@yoursite.com
                </a>{' '}
                <a href='tel:+0123456789' className='footer-ci'>
                  <i className='lnr lnr-phone-handset'></i>+0123 456 789
                </a>
              </span>
              <span className='social pull-right'>
                <a href='/'>
                  <i className='fa fa-facebook'></i>
                </a>
                <a href='/'>
                  <i className='fa fa-twitter'></i>
                </a>
                <a href='/'>
                  <i className='fa fa-pinterest'></i>
                </a>
                <a href='/'>
                  <i className='fa fa-tumblr'></i>
                </a>
                <a href='/'>
                  <i className='fa fa-instagram'></i>
                </a>
              </span>
            </p>
          </div>
        </footer>
      </>
    )
  }
}
