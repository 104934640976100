import React from 'react'

import Home from './components/Home/Home'
import Artists from './components/Artists/Artists'
import Artist from './components/Artist/Artist'
import Art from './components/Art/Art'
import About from './components/About/About'
import Navigation from './components/Navigation'
import Register from './components/Register/Register'
import Footer from './components/Footer'

import './App.scss'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

function App() {
  return (
    <>
      <Router>
        <>
          <Navigation />
          <Switch>
            <Route path='/home' component={Home} />
            <Route path='/art' component={Art} />
            <Route path='/artists/:ipfsHash' component={Artist} />
            <Route path='/artists' component={Artists} />
            <Route path='/about' component={About} />
            <Route path='/register' component={Register} />
            <Route path='/' component={Home} />
          </Switch>
          <Footer />
        </>
      </Router>
    </>
  )
}

export default App
