import React from "react";

const About = () => (
  <>
    <div id="about-banner" className="dark has-fixed-top">
      <div className="banner text-center">
        <div className="container-fluid">
          <div className="page-header">
            <h2>About Us</h2>
          </div>
          <p>An Art Economy On The Blockchain</p>
          <a href="#page-content" className="page-scroll">
            <i className="lnr lnr-chevron-down scroll-down"></i>
          </a>
        </div>
      </div>
    </div>

    <div id="page-content" className="container-fluid">
      <div className="section-w-image">
        <div className="row">
          {/* <div className="col-md-5 about-image"><p></p></div> */}
          <div className="col-md-12 col-md-offset-5 section-description dark">
            <h3>About Us</h3>

            <h5>Designed for you the Artist and Student of Art's.</h5>
            <p>
              Over the past 3 years We the Owners of Trendie became fascinated
              with the Art World, it's amazing how Cultures & Lifestyles inspire
              Art so we wanted to bring this platform for All Art Lovers to
              Enjoy.
            </p>
            <p>
              It is Designed to bring you closer to the Artist and there
              Creativity to be Inspired and Grow!
            </p>
            <p>
              With great features such as Zoom Lens you can capture the most
              attractive piece of Art that inspires you with the means to buy at
              Affordable Prices.
            </p>
            <p>
              So if your working on that project and need Inspiration Images or
              Marketing Strategy you can create the perfect Board or Proposal or
              Watch the Tutorials to learn how to.
            </p>
            <p>
              Designed with you in mind, We hope you Enjoy Scrolling through the
              pages as much as We did creating a One Stop Place for All kinds of
              Expression!
            </p>
            
          </div>
        </div>
      </div>

      {/* <section id="facts">
        <div className="row">
    
            <div className="col-sm-6 col-md-3 text-center">
                <i className="fact-icon lnr lnr-camera"></i>
                <h3 className="timer" id="photos" data-to="8981" data-speed="1000">8981</h3>
                <h5 className="fact-title">Photos Taken</h5>
            </div>
            

            
            <div className="col-sm-6 col-md-3 text-center">
                <i className="fact-icon lnr lnr-briefcase"></i>
                <h3 className="timer" id="projects" data-to="2763" data-speed="1500">2763</h3>
                <h5 className="fact-title">Projects Completed</h5>
            </div>
            

            
            <div className="col-sm-6 col-md-3 text-center">
                <i className="fact-icon lnr lnr-users"></i>
                <h3 className="timer" id="clients" data-to="579" data-speed="2000">579</h3>
                <h5 className="fact-title">Clients</h5>
            </div>
            

            
            <div className="col-sm-6 col-md-3 text-center">
                <i className="fact-icon lnr lnr-coffee-cup"></i>
                <h3 className="timer" id="coffee" data-to="9834" data-speed="2500">9834</h3>
                <h5 className="fact-title">Cups of Coffee</h5>
            </div>
            
        </div>
    </section> */}

      <section id="team">
        <div className="page-header text-center">
          <h2>The Team</h2>
        </div>
        <div className="row">
          <div className="col-sm-6 col-md-4">
            <div className="team block text-center">
              <img src="images/team.jpg" alt="" />
              <h6>Lorraine Duignan</h6>
              <p className="team-role">Photographer</p>
              <p className="social center">
                <a href="/">
                  <i className="fa fa-facebook"></i>
                </a>
                <a href="/">
                  <i className="fa fa-twitter"></i>
                </a>
                <a href="/">
                  <i className="fa fa-pinterest"></i>
                </a>
                <a href="/">
                  <i className="fa fa-instagram"></i>
                </a>
              </p>
            </div>
          </div>

          <div className="col-sm-6 col-md-4">
            <div className="team block text-center">
              <img src="images/team.jpg" alt="" />
              <h6>Mark Robson</h6>
              <p className="team-role">Photo Editor</p>
              <p className="social center">
                <a href="/">
                  <i className="fa fa-facebook"></i>
                </a>
                <a href="/">
                  <i className="fa fa-twitter"></i>
                </a>
                <a href="/">
                  <i className="fa fa-pinterest"></i>
                </a>
                <a href="/">
                  <i className="fa fa-instagram"></i>
                </a>
              </p>
            </div>
          </div>

          <div className="col-sm-6 col-md-4">
            <div className="team block text-center">
              <img src="images/team.jpg" alt="" />
              <h6>Colin Bowen</h6>
              <p className="team-role">Project Manager</p>
              <p className="social center">
                <a href="/">
                  <i className="fa fa-facebook"></i>
                </a>
                <a href="/">
                  <i className="fa fa-twitter"></i>
                </a>
                <a href="/">
                  <i className="fa fa-pinterest"></i>
                </a>
                <a href="/">
                  <i className="fa fa-instagram"></i>
                </a>
              </p>
            </div>
          </div>

          {/* <div className="col-sm-6 col-md-3">
                <div className="team block text-center">
                    <img src="images/team.jpg" alt="" />
                    <h6>Johanna Doe</h6>
                    <p className="team-role">Stylist</p>
                    <p className="social center">
                        <a href="/"><i className="fa fa-facebook"></i></a>
                        <a href="/"><i className="fa fa-twitter"></i></a>
                        <a href="/"><i className="fa fa-pinterest"></i></a>
                        <a href="/"><i className="fa fa-instagram"></i></a>
                    </p>
                </div>
            </div> */}
        </div>
      </section>

      <section id="testimonials-slider" className="dark">
        <div className="page-header text-center">
          <h2>Testimonials</h2>
        </div>
        <div
          id="testimonial-slider"
          className="carousel slide"
          data-ride="carousel"
        >
          <ol className="carousel-indicators">
            <li
              data-target="#testimonial-slider"
              data-slide-to="0"
              className="active"
            ></li>
            <li
              data-target="#testimonial-slider"
              data-slide-to="1"
              className=""
            ></li>
            <li
              data-target="#testimonial-slider"
              data-slide-to="2"
              className=""
            ></li>
          </ol>
          <div className="carousel-inner" role="listbox">
            <div className="item active">
              <div className="testimonial">
                <img src="images/about.jpg" alt="" />
                <h3>
                  Nam eleifend vestibulum dignissim. Quisque in ante metus. Nam
                  id facilisis nunc, eu vulputate sapien.
                </h3>
                <h5 className="customer-name">Johnathan Doe</h5>
              </div>
            </div>

            <div className="item">
              <div className="testimonial">
                <img src="images/about.jpg" alt="" />
                <h3>
                  Nam eleifend vestibulum dignissim. Quisque in ante metus. Nam
                  id facilisis nunc, eu vulputate sapien.
                </h3>
                <h5 className="customer-name">Erica Doe</h5>
              </div>
            </div>

            <div className="item">
              <div className="testimonial">
                <img src="images/about.jpg" alt="" />
                <h3>
                  Nam eleifend vestibulum dignissim. Quisque in ante metus. Nam
                  id facilisis nunc, eu vulputate sapien.
                </h3>
                <h5 className="customer-name">Jameson Doe</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </>
);

export default About;
