import React, { Component } from 'react'

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

export default class Navigation extends Component {
  state = {}
  render() {
    return (
      <Navbar bg='light' expand='lg'>
        <Navbar.Brand href='/'>Trendie - Cyber Gallery</Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='mr-auto' as='ul'>
            <li>
              <Nav.Link href='/'>Home</Nav.Link>
            </li>
            <li>
              <Nav.Link href='/artists'>Artists</Nav.Link>
            </li>
            <li>
              <Nav.Link href='/art'>Art</Nav.Link>
            </li>
            <li>
              <Nav.Link href='/about'>About</Nav.Link>
            </li>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}
