import React from "react";

const Art = () => (
  <section>
    <header>
      <h1>Art</h1>
    </header>
    <p>Coming soon</p>
  </section>
);

export default Art;
