import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import IPFS from 'ipfs-mini'
import { JsonRpc } from 'eosjs'

const EOS_HTTP_ENDPOINT = 'http://localhost:8888'
const ipfs = new IPFS({ host: 'localhost', port: 5001, protocol: 'http' })

const rpc = new JsonRpc(EOS_HTTP_ENDPOINT)

interface ArtistInterface {
  name: string
  username: string
  image: string
  bio: string
  birthDate: string
  url: string
  ipfsProfileHash: string
}

export async function fetchRows(options: any) {
  const mergedOptions = {
    json: true,
    limit: 9999,
    ...options,
  }

  const result = await rpc.get_table_rows(mergedOptions)
  return result.rows
}

async function getIpfsPin(ipfsHash: string) {
  const result = await ipfs.catJSON(ipfsHash)
  return { ...result, ipfsProfileHash: ipfsHash }
}

async function getArtists() {
  const artists = await fetchRows({
    code: 'trendie',
    scope: 'trendie',
    table: 'artists',
    reverse: false,
    show_payer: false,
  })

  return Promise.all(
    artists.map((artist: any) => getIpfsPin(artist.ipfsProfileHash))
  )
}

class Artists extends Component {
  state = {
    artists: [] as ArtistInterface[],
  }

  async componentDidMount() {
    const artists = await getArtists()
    this.setState({ artists: artists })
  }

  render() {
    return (
      <section id='team'>
        <header className='page-header text-center'>
          <h1>Artists</h1>
        </header>
        <div className='row'>
          {this.state.artists.map((artist) => (
            <article className='col-sm-6 col-md-3'>
              <Link to={`/artists/${artist.ipfsProfileHash}`}>
                <div className='team block text-center'>
                  <img src={artist.image} alt={artist.name} />
                  <h6>{artist.name}</h6>
                  <p className='team-role'>Artist</p>
                </div>
              </Link>
            </article>
          ))}
        </div>
      </section>
    )
  }
}

export default Artists
